import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import personalisedicon from '../../images/smartphone.svg'
import HalfCard from '../HalfCard'
import Button from '../Button'
import { Link } from 'gatsby'

const Personalised = ({ data }) => {
  const {
    heading,
    subHeading,
    description,
    btnText,
    btnUri,
    image,
    icon,
  } = data
  return (
    <section className="about-half-card">
      <Container fluid={true}>
        <Row>
          <Col xl={6}>
            <HalfCard
              type="primary"
              image={image.localFile.childImageSharp.fluid}
              icon={icon.localFile.childImageSharp.fixed}
              text="100%"
              heading="Personalised approach"
            />
          </Col>
          <Col className="about-half-card__col" xl={6}>
            <div>
              <div className="about-half-card__sub-heading">{subHeading}</div>
              <h2 className="about-half-card__heading">{heading}</h2>
              <div
                className="about-half-card__text"
                dangerouslySetInnerHTML={{ __html: description }}
              />
              <Link to={btnUri}>
                <Button type="primary" label={btnText} />
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Personalised
