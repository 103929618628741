import React from 'react'
import Img from 'gatsby-image'

const TeamCard = props => {
  const { image, heading, text } = props
  return (
    <div className="team-card">
      <Img className="team-card__image" fluid={image} />
      <div className="team-card__wrapper">
        <h2 className="team-card__heading">{heading}</h2>
        <h5 className="team-card__text">{text}</h5>
      </div>
    </div>
  )
}

export default TeamCard
