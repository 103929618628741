import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Carousel, { consts } from 'react-elastic-carousel'
import Logistics from '../components/About/Logistics'
import Personalised from '../components/About/Personalised'
import Button from '../components/Button'
import Hero from '../components/EquipmentTabs/Hero'
import Footer from '../components/Footer'
import Layout from '../components/Layout'
import Location from '../components/Location'
import SEO from '../components/seo'
import TeamCard from '../components/TeamCard'
import leftarrow from '../images/team-arrow-left.svg'
import rightarrow from '../images/team-arrow-right.svg'
import { groupByN } from '../utils'


class AboutUs extends React.Component {
  myArrow({ type, onClick, isEdge }) {
    const pointer = type === consts.PREV ? '<' : '>'
    return (
      <button
        className="about-team__arrows"
        onClick={onClick}
        disabled={isEdge}
      >
        {pointer}
      </button>
    )
  }

  render() {
    const { seo, aboutPage } = this.props.data.wpPage
    const {
      header,
      team,
      personalized,
      logistics,
      advantage,
      learnMore,
    } = aboutPage

    // console.log(advantage)

    return (
      <Layout>
        <SEO title={seo.title} description={seo.metaDesc} />
        <Hero
          type="primary"
          image={header.bgImage.localFile.childImageSharp.fluid}
          title={header.heading}
        />
        <section className="about-team">
          <Container fluid>
            <Row>
              <Col className="about-team__col-left" xl={5}>
                <div className="about-team__sub-heading">{team.subHeading}</div>
                <h1 className="about-team__heading">{team.heading}</h1>
                <div
                  className="about-team__text"
                  dangerouslySetInnerHTML={{ __html: team.description }}
                />
                <div className="about-team__buttons-wrapper">
                  <div
                    className="about-team__button"
                    onClick={() => this.carousel.slidePrev()}
                  >
                    <img src={leftarrow} />
                  </div>
                  <div
                    className="about-team__button"
                    onClick={() => this.carousel.slideNext()}
                  >
                    <img src={rightarrow} />
                  </div>
                </div>
              </Col>
              <Col className="about-team__carousel" xl={7}>
                <Carousel
                  renderArrow={this.myArrow}
                  itemsToScroll={1}
                  itemsToShow={1}
                  ref={ref => (this.carousel = ref)}
                >
                  {groupByN(2, team.members).map((groupedItems, i) => (
                    <div className="about-team__team-wrapper" key={i}>
                      {groupedItems.map(item => (
                        <TeamCard
                          image={item.image.localFile.childImageSharp.fluid}
                          heading={item.name}
                          text={item.position}
                        />
                      ))}
                    </div>
                  ))}
                </Carousel>
              </Col>
              <Col className="about-team__carousel-mobile" xl={7}>
                {groupByN(2, team.members).map((groupedItems, i) => (
                  <div className="about-team__team-wrapper" key={i}>
                    {groupedItems.map(item => (
                      <TeamCard
                        image={item.image.localFile.childImageSharp.fluid}
                        heading={item.name}
                        text={item.position}
                      />
                    ))}
                  </div>
                ))}
              </Col>
            </Row>
          </Container>
        </section>

        <Personalised data={personalized} />
        <Logistics data={logistics} />

        <section className="about-advantage">
          <Container fluid>
            <Row>
              <Col xl={6}>
                <h3 className="about-advantage__heading">
                  {advantage.heading}
                </h3>
                <div
                  className="about-advantage__text"
                  dangerouslySetInnerHTML={{ __html: advantage.description }}
                />
              </Col>
              <Col className="about-advantage__col" xl={6}>
                {advantage.items.map(item => (
                  <div className="about-advantage__wrapper" key={item.name}>
                    <div className="about-advantage__image-wrapper">
                      <Img fixed={item.icon.localFile.childImageSharp.fixed} />
                    </div>
                    <div className="about-advantage__label">{item.name}</div>
                  </div>
                ))}
              </Col>
            </Row>
          </Container>
        </section>

        <section className="about-call-us">
          <Container fluid>
            <Row>
              <Col className="about-call-us__col" lg={6} xl={6}>
                <div
                  className="about-call-us__text"
                  dangerouslySetInnerHTML={{ __html: learnMore.content }}
                />
              </Col>
              <Col className="about-call-us__col" lg={6} xl={6}>
                <Link to={learnMore.btnUri}>
                  <Button type="primary" label={learnMore.btnText} />
                </Link>
              </Col>
            </Row>
          </Container>
        </section>

        <Location />
        <Footer />
      </Layout>
    )
  }
}

export const query = graphql`
  query getAboutUsPage {
    wpPage(uri: { eq: "/about-us/" }) {
      seo {
        title
        metaDesc
      }
      aboutPage {
        header {
          heading
          bgImage {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        team {
          heading
          subHeading
          description
          members {
            name
            position
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 340, maxHeight: 440) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        personalized {
          heading
          subHeading
          description
          btnText
          btnUri
          image {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 736, maxHeight: 634) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          icon {
            localFile {
              childImageSharp {
                fixed(height: 68, width: 95) {
                  src
                  width
                  height
                }
              }
            }
          }
        }
        logistics {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          heading
          subHeading
          description
          items {
            name
            details
            icon {
              localFile {
                childImageSharp {
                  fixed(height: 62) {
                    src
                    width
                    height
                  }
                }
              }
            }
          }
        }
        advantage {
          heading
          description
          items {
            name
            icon {
              localFile {
                childImageSharp {
                  fixed(width: 50) {
                    src
                    width
                    height
                  }
                }
              }
            }
          }
        }
        learnMore {
          content
          btnText
          btnUri
        }
      }
    }
  }
`

export default AboutUs
