import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import truckicon from '../../images/truck-icon.svg'
import inhandicon from '../../images/in-hand.svg'

const Logistics = ({ data }) => {
  const { image, heading, subHeading, description, items } = data
  return (
    <section className="about-logistic">
      <Img
        fluid={image.localFile.childImageSharp.fluid}
        className="about-logistic__image"
      />
      <Container fluid={true} className="about-logistic__container">
        <Row>
          <Col className="about-logistic__col" xl={12}>
            <div className="about-logistic__sub-heading">{subHeading}</div>
            <h2 className="about-logistic__heading">{heading}</h2>
            <div
              className="about-logistic__text"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </Col>
        </Row>
        <Row>
          {items.map(item => (
            <Col className="about-logistic__col-bottom" xl={6} key={item.name}>
              <div>
                <Img fixed={item.icon.localFile.childImageSharp.fixed} />
                <h3 className="about-logistic__sec-heading">{item.name}</h3>
                <div
                  className="about-logistic__sec-text"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default Logistics
